import axios from '@axios';

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        fetchCriticalities(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/criticalities', { params: queryParams })
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        },
        fetchCriticality(ctx, { id }) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/criticalities/${id}/edit`)
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        },
        updateCriticality(ctx, { id, payload }) {
            return new Promise((resolve, reject) => {
                axios
                    .put(`/criticalities/${id}`, payload)
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        },
		uploadTemplate(ctx, payload) {
			return new Promise((resolve, reject) => {

				const formData = new FormData()
				
				const { file, ...body } = payload

				formData.append("file", file)
				formData.append("data", JSON.stringify(body))

                axios
                    .post(`/criticalities/upload`, formData, {
                        headers: { 'Content-Type': 'multipart/form-data' }
                    })
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });	
		}
    }
}