<template>
	<validation-observer ref="refForm">
		<b-form
			@submit.prevent="onSubmit"
			@reset.prevent="resetForm"
		>
			<b-row>
				<b-col md="12">
					<b-card
						footer-tag="footer"
						class="mb-0"
					>
						<b-row>
							<b-col
								cols="12"
								md="4"
								class="mb-2"
							>
								<label for="billingLevel">
									<strong>NIVEL DE FACTURACIÓN</strong>
								</label>
								<validation-provider
									#default="{ errors }"
									:rules="isRequired ? 'required' : ''"
									name="Nivel de Facturación"
								>
									<b-form-input
										id="billingLevel"
										v-model="formData.billing_level"
										placeholder="Ej. 1000000.00"
										:state="errors.length > 0 ? false : null"
										trim
									/>

									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-col>

							<b-col
								cols="12"
								md="4"
								class="mb-2"
							>
								<label for="productionContinuity">
									<strong>CONTINUIDAD EN LA PRODUCCIÓN</strong>
								</label>
								<validation-provider
									#default="{ errors }"
									:rules="isRequired ? 'required' : ''"
									name="Continuidad en la Producción"
								>
									<v-select
										id="productionContinuity"
										placeholder="Seleccione"
										v-model="formData.production_continuity"
										:state="errors.length > 0 ? false : null"
										:options="options.productionContinuities"
									/>

									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-col>

							<b-col
								cols="12"
								md="4"
								class="mb-2"
							>
								<label for="workforceQuantity">
									<strong>CANTIDAD DE MANO DE OBRA</strong>
								</label>
								<validation-provider
									#default="{ errors }"
									:rules="isRequired ? 'required' : ''"
									name="Cantidad de Mano de Obra"
								>
									<b-form-input
										id="workforceQuantity"
										v-model="formData.workforce_quantity"
										placeholder="Ej. 100"
										:state="errors.length > 0 ? false : null"
										trim
									/>

									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-col>
						</b-row>

						<template #footer>
							<b-row>
								<b-col cols="12">
									<!-- BUTTON SUBMIT -->
									<b-button-loading
										text="EVALUAR"
										type="submit"
										:processing="processing"
									/>
								</b-col>
							</b-row>
						</template>
					</b-card>
				</b-col>
			</b-row>
		</b-form>
	</validation-observer>
</template>

<script>
import useNotifications from "@notifications"
import BButtonLoading from "@core/components/b-button-loading/BButtonLoading.vue"
import { ref, onBeforeMount, onUnmounted } from "@vue/composition-api"
import { ValidationProvider, ValidationObserver } from "vee-validate"
import criticalityStoreModule from "../criticalityStoreModule"
import { required } from "@validations"
import vSelect from "vue-select"
import store from "@/store"
import router from "@/router"

export default {
	components: {
		ValidationProvider,
		ValidationObserver,

		BButtonLoading,

		required,
		vSelect
	},
	setup() {
		// USE TOAST
		const { toastNotification } = useNotifications()

		// REFS
		const refForm = ref(null)
		const processing = ref(false)
		const isRequired = ref(false)
		const options = ref({})

		const criticalityData = ref(null)

		const blankData = {
			billing_level: null,
			production_continuity: null,
			workforce_quantity: null
		}

		const formData = ref(JSON.parse(JSON.stringify(blankData)))

		const CRITICALITY_APP_STORE_MODULE_NAME = "app-criticality"

		// REGISTER MODULE
		if (!store.hasModule(CRITICALITY_APP_STORE_MODULE_NAME))
			store.registerModule(
				CRITICALITY_APP_STORE_MODULE_NAME,
				criticalityStoreModule
			)

		// UNREGISTER ON LEAVE
		onUnmounted(() => {
			if (store.hasModule(CRITICALITY_APP_STORE_MODULE_NAME))
				store.unregisterModule(CRITICALITY_APP_STORE_MODULE_NAME)
		})

		// CALL FUNCTION TO POPULATE OPTIONS FOR THE FILTERS
		onBeforeMount(() => {
			getSelectFilters()
			fetchCriticality()
		})

		const fetchCriticality = () => {
			store
				.dispatch("app-criticality/fetchCriticality", {
					id: router.currentRoute.params.id
				})
				.then((response) => {
					criticalityData.value = response.data.criticality
					setData()
				})
				.catch((error) => {
					if (error.response.status === 404) {
						criticalityData.value = undefined
					}
				})
		}

		const getSelectFilters = async () => {
			options.value = {
				productionContinuities: [
					{ value: "DIRECTAMENTE", label: "DIRECTAMENTE" },
					{ value: "INDIRECTAMENTE", label: "INDIRECTAMENTE" },
					{ value: "SOLO EL PROCESO", label: "SOLO EL PROCESO" }
				],
				activityRisks: [
					{ value: "BAJO", label: "BAJO" },
					{ value: "MEDIO", label: "MEDIO" },
					{ value: "ALTO", label: "ALTO" }
				]
			}
		}

		const setData = () => {
			const production_continuity = options.value.productionContinuities.find(
				(item) => item.value === criticalityData.value.production_continuity
			)

			formData.value = {
				billing_level: criticalityData.value.billing_level
					? parseFloat(criticalityData.value.billing_level).toFixed(2)
					: null,
				workforce_quantity: criticalityData.value.workforce_quantity,
				production_continuity
			}
		}

		const onSubmit = async () => {
			const isValid = await refForm.value.validate()
			if (!isValid) {
				toastNotification("Complete los campos requeridos", false)
				return
			}

			processing.value = true

			const id = criticalityData.value.id

			const payload = {
				billing_level: formData.value.billing_level,
				workforce_quantity: formData.value.workforce_quantity,
				production_continuity:
					formData.value.production_continuity?.value || null
			}

			store
				.dispatch("app-criticality/updateCriticality", { id, payload })
				.then((response) => {
					isRequired.value = false
					toastNotification(response.data.message)
					router.push({ name: "criticalities-list" })
				})
				.catch((err) => {
					const message =
						err.response.data.message || "Error al evaluar criticidad"
					toastNotification(message, false)
				})
				.finally(() => (processing.value = false))
		}

		return {
			// DATA
			processing,
			formData,
			options,
			isRequired,

			// REFS
			refForm,

			// METHODS
			onSubmit
		}
	}
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>